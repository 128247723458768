//////////////////////////////////////////////////////
// All nav-bar and nav-menu functionality
//////////////////////////////////////////////////////

function navBarFunc() {
  //nav-bar and nav-menu open and close variables
  var navBar = document.querySelector(".nav-bar");
  let navBarMenuBtn = document.querySelector(".menu-btn");
  let navBarMenu = document.querySelector(".nav-bar__menu");
  let body = document.querySelector("body");
  let navMenuLabel = document.getElementById("menu-label");

  //toggles classes on the menu click
  navBarMenuBtn.addEventListener("click", () => {
    navBar.classList.toggle("menu-open");
    navBarMenuBtn.classList.toggle("close");
    navBarMenu.classList.toggle("open");

    //check if body already has stop-scroll
    if (!body.classList.contains("stop-scroll")) {
      body.classList.add("stop-scroll");
    } else {
      body.classList.toggle("stop-scroll");
    }

    //nav menu links animation on open and close logic
    if (navBarMenuBtn.classList.contains("close")) {
      navMenuLabel.textContent = "Close";
      navMenuLabel.style.color = "#e4002b";
    } else {
      navMenuLabel.textContent = "Menu";
      navMenuLabel.style.color = "white";
    }
  });

  // Variables for link options on toggle
  let navLinks = document.querySelectorAll(".nav-links");
  let linkUnderline = document.querySelectorAll(".link-underline");
  let navLinkMenus = document.querySelectorAll(".nav-link__menu");
  let navLinkMenuBackBtn = document.querySelectorAll(".nav-link__menu-backbtn");
  let decorSquare1 = document.querySelector(".square1");
  let decorSquare2 = document.querySelector(".square2");
  let decorSquare3 = document.querySelector(".square3");
  let decorSquare4 = document.querySelector(".square4");

  //toggle link classes for menus and underline
  for (let i = 0; i < navLinks.length; i++) {
    navLinks.item(i).addEventListener("click", () => {
      navLinks.forEach((link) => {
        link.classList.remove("active");
      });
      linkUnderline.forEach((line) => {
        line.classList.remove("active");
        line.style.width = "0px";
      });
      navLinkMenus.forEach((menu) => {
        menu.classList.remove("move");
      });

      //Grabs squares on page the animate on sub menus
      decorSquare1.classList.remove("move");
      decorSquare2.classList.remove("move");
      decorSquare3.classList.remove("move");
      decorSquare4.classList.remove("move");

      //creates a random number for square animation

      const randomNumber = Math.floor(Math.random() * 100);

      //width set equal to width of link on underline that
      //pops up under nav menu link

      let lineWidth = navLinks.item(i).offsetWidth;

      setTimeout(() => {
        //uses random number to add classes on animated squares
        if (randomNumber <= 50) {
          setTimeout(() => {
            decorSquare1.classList.add("move");
            decorSquare2.classList.add("move");
          }, 100);
        } else {
          setTimeout(() => {
            decorSquare3.classList.add("move");
            decorSquare4.classList.add("move");
          }, 100);
        }

        //Adds classes to all needed elements to make nav menu
        //function correctly

        navLinkMenus.item(i).classList.add("move");
        navLinks.item(i).classList.add("active");
        linkUnderline.item(i).classList.add("active");
        linkUnderline.item(i).style.width = lineWidth + "px";
      }, 300);
    });

    // Logic to resets toggle menu when a new nav link is clicked
    navLinkMenuBackBtn.item(i).addEventListener("click", () => {
      navLinks.forEach((link) => {
        link.classList.remove("active");
      });
      linkUnderline.forEach((line) => {
        line.classList.remove("active");
        line.style.width = "0px";
      });
      navLinkMenus.forEach((menu) => {
        menu.classList.remove("move");
      });

      decorSquare1.classList.remove("move");
      decorSquare2.classList.remove("move");
      decorSquare3.classList.remove("move");
      decorSquare4.classList.remove("move");
    });
  }
}

// exports function to be used on other scripts
export default navBarFunc;
