//sass goes here
import "../scss/info-pages.scss";

//imported logic goes here

import navBarFunc from "./js-comps/navbar";
import collapsibleFunc from "./js-comps/collapsible";
import translatorFunc from "./js-comps/translator";
import gdprFunctionality from "./js-comps/gdpr";

navBarFunc();
collapsibleFunc();
translatorFunc();
gdprFunctionality();
//custom logic goes here
