//////////////////////////////////////////////////////
// Collapsible functionality if needed on page
//////////////////////////////////////////////////////

const collapsibleFunc = () => {
  // Grab collapsible elements
  const collapsibles = document.querySelectorAll(".collapsible");
  const collapsibleButtons = document.querySelectorAll(
    ".collapsible__notifier"
  );

  // Add on click event for each element
  collapsibleButtons.forEach((collapsibleButton, i) => {
    collapsibleButton.addEventListener("click", () => {
      // remove class from expanded collapsible
      collapsibles.forEach((collapsible) => {
        collapsible.classList.remove("expanded");
      });

      // Add class to expanded collapsible
      collapsibles[i].classList.add("expanded");
    });
  });
};

// exports function to be used on other scripts
export default collapsibleFunc;
