//////////////////////////////////////////////////////
// Translator pop up functionality
//////////////////////////////////////////////////////

function translatorFunc() {
  // Grabs translator elements in pop-up
  const translatorFixed = document.getElementById("translator-fixed");
  const translatorTab = document.getElementById("translator-tab");
  const translatorTabWording = document.getElementById(
    "translator-tab-wording"
  );

  // Add on click event listener
  translatorTab.addEventListener("click", () => {
    translatorFixed.classList.toggle("closed");

    translatorFixed.classList.contains("closed")
      ? (translatorTabWording.textContent = "Translate")
      : (translatorTabWording.textContent = "Close");
  });
}

// exports function to be used on other scripts
export default translatorFunc;
