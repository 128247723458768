//////////////////////////////////////////////////////
// GDPR pop up functionality
//////////////////////////////////////////////////////

function gdprFunctionality() {
  const gdpr = document.querySelector(".gdpr");
  const acceptGdprBtn = document.getElementById("accept-gdpr");

  let accept = localStorage.getItem("gdpr-accept");

  window.addEventListener("load", () => {
    if (!accept) {
      gdpr.classList.add("open");
    }
  });

  acceptGdprBtn.addEventListener("click", () => {
    gdpr.classList.remove("open");
    localStorage.setItem("gdpr-accept", "true");
  });
}

// exports function to be used on other scripts
export default gdprFunctionality;
